import React from 'react';
import SEO from '../components/Seo';
import Layout from '../components/Layout';

export default function NotFound() {
  return (
    <Layout>
      <SEO title="Gabriel Mendes" />
      <div>
        <h1>Not Found</h1>
      </div>
    </Layout>
  );
}
